<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card class="rounded-xl" :loading="loading" :disabled="loading">
    <v-card-title class="headline primary white--text">
      {{ "Edit " + getpatientN }}
    </v-card-title>
    <v-card-text class="pt-3">
      <v-form ref="patientForm" v-model="formValid">
        {{/* Datos personales */}}
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="name"> First Name </label>
            <v-text-field v-model="form.firstName" outlined dense />
          </v-col>
          <v-col cols="12" sm="4" class="px-1">
            <label class="font-weight-medium" for="midlename">
              Middle Name
            </label>
            <v-text-field v-model="form.midleName" outlined dense />
          </v-col>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="lastname"> Last Name </label>
            <v-text-field
              v-model="form.lastName"
              class="rounded"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="ssn"
              >SSN
              <template v-if="last4ssn">
                <span class="red--text">Current: (XXX-XX-{{ last4ssn }})</span>
              </template>
            </label>
            <v-text-field v-model="form.ssn" outlined dense />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="dateofbirth"
              >Date of Birth
            </label>
            <ma-date-picker v-model="form.birthDate" past :editable="true" />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="gender">Gender </label>
            <v-select
              :items="genders"
              item-text="label"
              item-value="value"
              v-model="form.isMale"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label for="phone">Phone </label>
            <v-text-field
              v-model="homePhone"
              prepend-inner-icon="mdi-phone"
              autocomplete="tel"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="email">Email </label>
            <v-text-field
              v-model.number="form.email"
              type="email"
              :rules="[rules.email]"
              autocomplete="email"
              prepend-inner-icon="mdi-email"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="legal">Legal Status </label>
            <v-select
              :items="legalstatus"
              v-model="form.legalStatus"
              item-text="label"
              item-value="value"
              dense
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="cernatur">ID Number </label>
            <br />

            <v-text-field outlined v-model="form.idNumber" dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="cernatur"
              >Expiration Date (if apply)
            </label>
            <ma-date-picker v-model="form.expDate" past :editable="true" />
          </v-col>
          <v-col cols="12" sm="3" class="pl-1">
            <label class="font-weight-medium" for="cernatur"
              >Is Married?
            </label>
            <v-select
              :items="civilstatus"
              item-text="label"
              item-value="value"
              outlined
              dense
              v-model="form.isMaried"
            ></v-select>
          </v-col>
        </v-row>

        {{/* state, city and zip */}}

        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="address">Address </label>
            <v-text-field
              v-model="form.address.addressLine1"
              prepend-inner-icon="mdi-map-marker"
              dense
              autocomplete="street-address"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="apto">Apto </label>
            <v-text-field
              v-model="form.address.addressLine2"
              dense
              autocomplete="apto-address"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="state">State </label>
            <v-autocomplete
              v-model="state"
              :items="states"
              item-text="name"
              item-value="id"
              :loading="loadingStates"
              autocomplete="off"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2" class="pr-1">
            <label class="font-weight-medium" for="city">City </label>
            <v-autocomplete
              :disabled="stateCities.length == 0"
              v-model.number="form.address.city"
              :items="stateCities"
              item-text="name"
              item-value="id"
              :loading="loadingCities"
              autocomplete="off"
              mandatory
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="zip">Zip </label>
            <v-text-field
              v-model="form.address.zipCode"
              :rules="[rules.zip]"
              autocomplete="postal-code"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <label class="font-weight-medium" for="address"
              >Mailing Address (if different)
            </label>
            <v-text-field
              prepend-inner-icon="mdi-map-marker"
              v-model="form.mailAddress.addressLine1"
              dense
              autocomplete="street-mail"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="apto">Apto </label>
            <v-text-field
              v-model="form.mailAddress.addressLine2"
              dense
              autocomplete="apto-mail"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="state">State </label>
            <v-autocomplete
              :items="states"
              v-model="state1"
              item-text="name"
              item-value="id"
              :loading="loadingStates"
              autocomplete="off"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2" class="pr-1">
            <label class="font-weight-medium" for="city">City </label>
            <v-autocomplete
              :disabled="stateCities1.length == 0"
              :items="stateCities1"
              item-text="name"
              item-value="id"
              :loading="loadingCities1"
              autocomplete="off"
              v-model="form.mailAddress.city"
              mandatory
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="zip">Zip </label>
            <v-text-field
              v-model="form.mailAddress.zipCode"
              autocomplete="postal-code"
              outlined
              dense
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="4" class="cerofnat"
            ><label class="font-weight-medium" for="cernatur"
              >Employed or Self-Employed
            </label>
            <br />
            <label class="font-weight-medium" for="ifemp"
              >(if Employed, Company Name)
            </label>
            <v-text-field
              outlined
              v-model="form.employedCompany"
              dense
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="3" class="px-1 pt-4 mt-2"
            ><label class="font-weight-medium" for="workphone"
              >Work Phone
            </label>
            <v-text-field
              prepend-inner-icon="mdi-phone"
              outlined
              :rules="[rules.phone1]"
              v-model="form.workPhone"
              dense
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="3" class="pr-1 pt-4 mt-2"
            ><label class="font-weight-medium" for="posocc"
              >Position/Occupation
            </label>
            <v-text-field v-model="form.ocupation" outlined dense></v-text-field
          ></v-col>
          <v-col cols="12" sm="2" class="pt-4 mt-2"
            ><label class="font-weight-medium" for="annualinco"
              >Annual Income
            </label>
            <v-text-field
              v-model="form.anualIncome"
              outlined
              dense
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4"
            ><label class="font-weight-medium" for="cprefer"
              >Contact Preference
            </label>
            <v-select
              outlined
              dense
              v-model="form.contactPreference"
              :items="['PHONE', 'EMAIL']"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" class="px-1"
            ><label class="font-weight-medium" for="preferlang"
              >Preferred Language
            </label>
            <v-select
              outlined
              dense
              :items="langs"
              item-text="label"
              item-value="value"
              v-model="form.preferLanguage"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" class=""
            ><label class="font-weight-medium" for="source">Source </label>
            <v-select
              :items="references"
              item-text="name"
              item-value="id"
              v-model="form.sourceId"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-divider class="mb-2"></v-divider>
        <v-row no-gutters>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source">Company </label>
            <v-select
              :items="companies"
              item-text="name"
              item-value="id"
              v-model="companySelect"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source"
              >Company State
            </label>
            <v-select
              :items="companyStates"
              :loading="loadingCompanyStates"
              item-text="state.name"
              :disabled="companySelect == null || companyStates.length == 0"
              v-model="form.companyStateId"
              item-value="state.id"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3">
            <label class="font-weight-medium" for="source">Metal Plan </label>
            <v-select
              :items="metalplan"
              item-text="label"
              item-value="value"
              v-model="form.policyPlan"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="3" class="px-1">
            <label class="font-weight-medium" for="source">Binder Pay </label>
            <v-radio-group
              v-model="form.binderDay"
              class="mt-0"
              mandatory
              row
              dense
              hide-details
            >
              <v-radio :value="true" :label="$t('Yes')" />
              <v-radio :value="false" :label="$t('No')"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="source"
              >Police Number
            </label>
            <v-text-field
              v-model="form.polizeNumber"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" class="px-1">
            <label class="font-weight-medium" for="source"
              >Effective Date
            </label>
            <ma-date-picker
              v-model="form.effectiveDate"
              past
              :editable="true"
            />
          </v-col>
          <v-col cols="12" sm="2" class="pr-1">
            <label class="font-weight-medium" for="source"
              >Monthly Payment
            </label>
            <v-text-field
              prepend-inner-icon="mdi-currency-usd"
              v-model="form.monthlyPay"
              outlined
              dense
            ></v-text-field
          ></v-col>
          <v-col cols="12" sm="2">
            <label class="font-weight-medium" for="source">Status </label>
            <v-select
              item-text="label"
              item-value="value"
              :items="policyStates"
              v-model="form.polizeStatus"
              @change="gotosetStatus()"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="source">Members </label>
            <v-text-field v-model="form.members" outlined dense></v-text-field
          ></v-col>
          <v-col cols="12" sm="2" class="pl-1">
            <label class="font-weight-medium" for="tags">Tag </label>
            <v-select
              item-text="name"
              item-value="id"
              :items="tags"
              v-model="form.tagId"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-form>

      <v-dialog
        v-model="setStatusObject.dialog"
        persistent
        :overlay="false"
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="primary white--text">
            <template v-if="setStatusObject.value">
              Status Change:
              {{ statusShow(oldStatus) + " to " + policySelectChange }}
            </template>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model.trim="setStatusObject.note"
              class="my-3"
              placeholder="Write a note"
              :disabled="loading"
              outlined
              counter="240"
            />
          </v-card-text>
          <template v-if="!validNote">
            <div class="mx-3">
              <v-alert dense outlined type="error">
                The note must be between 5 and 240 characters
              </v-alert>
            </div>
          </template>
          <v-card-actions class="d-flex justify-end">
            <v-btn
              color="error"
              :disabled="setStatusObject.loading"
              text
              @click="cancelSetStatus"
              >cancel</v-btn
            >
            <v-btn
              color="success"
              :loading="setStatusObject.loading"
              :disabled="setStatusObject.loading || !validNote"
              @click="setStatusObject.dialog = false"
              text
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>

    <v-divider class="mb-3" />

    <v-card-actions class="justify-end">
      <v-btn
        color="error"
        :disabled="loading"
        width="100"
        dark
        depressed
        rounded
        @click="cancel"
      >
        Cancel
      </v-btn>
      <!--  <v-btn
        color="grey"
        :disabled="loading"
        width="100"
        dark
        depressed
        rounded
        @click="$refs.patientForm.reset()"
      >
        Reset
      </v-btn> -->
      <v-btn
        class="ml-3"
        color="primary"
        :disabled="loading"
        :loading="loading"
        width="100"
        depressed
        rounded
        @click="update"
      >
        UPDATE
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import Errors from "../PdfSign/Errors.vue";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

import { mapActions, mapGetters, mapState } from "vuex";
import City from "@/models/City";
import { getAPI } from "@/api/axios-base";
import store from "@/store/index";
import cleanObject from "@/utils/cleanObject";
import rules from "@/components/account/rules";
import isEqual from "lodash/isEqual";
import { AxiosError } from "axios";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import Worker from "@/models/Worker";
import moment from "moment";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { cleanPhoneNumber } from "@/utils/formatPhone";

export default Vue.extend({
  components: { Errors, MaDatePicker },
  name: "form-update-patient",
  data() {
    return {
      formValid: false,
      birthDateMenu: false,
      loadingCompanyStates: false,
      companySelect: null,
      companyStates: [],

      rules: {
        email: rules.emailnull,
        zip: rules.isZipCode,
        phone: rules.isPhone,
        phone1: rules.isPhone1,
      },
      stateCities: [],
      loadingCities: false,
      state: 23,
      stateCities1: [],
      loadingCities1: false,
      state1: 23,
      setStatusObject: {
        dialog: false,
        value: null,
        loading: false,
        note: "",
      },
      loading: false,
      showErrors: false,
      errorMessage: "",
      langs: [
        {
          label: "English",
          value: "ENGLISH",
        },
        {
          label: "Spanish",
          value: "SPANISH",
        },
      ],
      year: "",
      month: "",
      civilstatus: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
      legalstatus: [
        { label: "Citizen", value: "CITIZEN" },
        { label: "Permanent Resident", value: "PERMANENT_RESIDENT" },
        { label: "Temporal Resident", value: "TEMPORAL_RESIDENT" },
      ],

      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
      last4ssn: "",
      isAdmins: false,
      oldStatus: "",
      homePhone: "",
      form: {
        contactPreference: "PHONE",
        firstName: "",
        lastName: "",
        midleName: "",
        birthDate: "",
        ssn: "",
        homePhone: "",
        preferLanguage: "ENGLISH",
        isMale: false,
        sourceId: 0,
        tagId: null,
        emergencyContact: "",
        emergencyPhone: "",
        emergencyRelation: "",
        email: "",
        leadUuid: "",
        policyPlan: "",
        effectiveDate: "",
        agentUuid: "",
        isMaried: false,
        legalStatus: "",
        binderDay: false,
        employedCompany: "",
        companyId: null,
        companyStateId: null,
        polizeStatus: null,
        monthlyPay: 0.0,
        workPhone: "",
        idNumber: "",
        expDate: "",
        anualIncome: "",
        members: null,
        ocupation: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
        },
        mailAddress: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          zipCode: "",
        },
      },

      formatNumber: (v) => /^[0-9]+$/.test(v) || this.$t("formatError"),
      validateYear: (v) => {
        if (v == null) {
          return "";
        }

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const userYear = parseInt(v);

        if (v.length != 4) {
          return this.$t("lengthYearError");
        }

        if (userYear > currentYear) {
          return true;
        }

        return this.$t("yearWrong");
      },
      formatCvc: (v) =>
        /^[0-9][0-9]{2,3}$/.test(v) || this.$t("formatCvcError"),

      validateCard: (v) => {
        const isCard =
          /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

        return isCard.test(v) || this.$t("wrongCard") + "";
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCoordinatorN",
      "getSellerN",
      "getpatientN",
      "isAdmin",
      "isSuper",
    ]),
    ...mapState("crmMedicFormModule", [
      "states",
      "loadingStates",
      "patientDetail",
    ]),
    ...mapState(["profile"]),
    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "references",
      "companies",
      "tags",
    ]),

    validNote() {
      if (
        this.setStatusObject.note == "" ||
        this.setStatusObject.note == null ||
        this.setStatusObject.note == undefined
      ) {
        return false;
      }
      if (
        this.setStatusObject.note.length < 5 ||
        this.setStatusObject.note.length > 240
      ) {
        return false;
      }
      return true;
    },

    months() {
      return [
        {
          value: 1,
          name: this.$t("January"),
        },
        {
          value: 2,
          name: this.$t("February"),
        },
        {
          value: 3,
          name: this.$t("March"),
        },
        {
          value: 4,
          name: this.$t("April"),
        },
        {
          value: 5,
          name: this.$t("May"),
        },
        {
          value: 6,
          name: this.$t("June"),
        },
        {
          value: 7,
          name: this.$t("July"),
        },
        {
          value: 8,
          name: this.$t("August"),
        },
        {
          value: 9,
          name: this.$t("September"),
        },
        {
          value: 10,
          name: this.$t("October"),
        },
        {
          value: 11,
          name: this.$t("November"),
        },
        {
          value: 12,
          name: this.$t("December"),
        },
      ];
    },

    validPhonePrimary() {
      const path = /^[1-9][0-9]{9}$/;
      if (path.test(this.form.homePhone)) {
        return true;
      }
      return false;
    },

    policyStates() {
      const states = this.isAdmins
        ? [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ]
        : [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ];
      return states;
    },

    policySelectChange() {
      if (
        this.setStatusObject.value == null ||
        this.setStatusObject.value == undefined ||
        this.setStatusObject.value == ""
      ) {
        return "";
      }
      const find = this.policyStates.find(
        (p) => p.value == this.setStatusObject.value
      );
      return find ? find.label : "";
    },
  },
  watch: {
    homePhone: {
      handler() {
        this.homePhone = cleanPhoneNumber(this.homePhone);
        if (this.homePhone[0] == "1") {
          this.homePhone = this.homePhone.slice(1);
        }
        this.form.homePhone = this.homePhone;
      },
    },
    state: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities = false;
        this.stateCities = cities;
      });
    },

    state1: function (stateId) {
      if (stateId == undefined || stateId == null) {
        this.stateCities1 = [];
        this.loadingCities1 = false;

        return;
      }

      this.loadingCities1 = true;
      this.actGetCityByState(stateId).then((cities) => {
        this.loadingCities1 = false;
        this.stateCities1 = cities;
      });
    },
    companySelect(val) {
      if (val != null) {
        this.form.companyId = val;
        this.getCompanyState(val);
      }
    },
  },
  async mounted() {
    this.loading = true;
    if (this.isAdmin || this.isSuper) {
      this.isAdmins = true;
    }
    await this.getPatientData();
    await this.actListStates();

    await this.actListReferences();
    await this.actListTags();
    await this.actListCompaniesNom();

    this.loading = false;
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",

      "actGetMedicalLocations",
    ]),
    ...mapActions("crmSettingsModule", [
      "actListReferences",
      "actListCompaniesNom",
      "actListTags",
    ]),

    statusShow(item) {
      if (item) {
        const st = PolicyStatus[item];
        return st;
      }

      return "-";
    },

    gotosetStatus() {
      if (this.form.polizeStatus != this.oldStatus) {
        this.setStatusObject = {
          dialog: true,
          value: this.form.polizeStatus,
          loading: false,
          note: "",
        };
      } else {
        this.setStatusObject = {
          dialog: false,
          value: null,
          loading: false,
          note: "",
        };
      }
    },
    cancelSetStatus() {
      this.setStatusObject = {
        dialog: false,
        value: null,
        loading: false,
        note: "",
      };
      this.form.polizeStatus = this.oldStatus;
    },

    update() {
      const { form: body } = this;
      let { ...rest } = body;

      if (body.birthDate) {
        body.birthDate = new Date(body.birthDate).toISOString();
      }
      if (body.expDate) {
        body.expDate = new Date(body.expDate).toISOString();
      }
      if (rest.homePhone != "" && rest.homePhone != null) {
        rest.homePhone = "+1" + rest.homePhone;
      }

      if (rest.monthlyPay) {
        rest.monthlyPay = Number(rest.monthlyPay);
      }
      if (rest.tagId) {
        rest.tagId = Number(rest.tagId);
      }
      if (rest.members) {
        rest.members = Number(rest.members);
      }
      if (rest.workPhone) {
        rest.workPhone = "+1" + rest.workPhone;
      }
      if (rest.companyId) {
        rest.companyId = Number(rest.companyId);
      }

      if (rest.companyStateId) {
        rest.companyStateId = Number(rest.companyStateId);
      }

      rest.address.state = this.state.toString();
      rest.mailAddress.state = this.state1.toString();
      if (rest.sourceId) {
        rest.sourceId = Number(rest.sourceId);
      }
      if (rest.sourceId == 0) {
        delete rest.sourceId;
      }
      if (
        rest.mailAddress.addressLine1 == "" ||
        rest.mailAddress.addressLine1 == null ||
        rest.mailAddress.addressLine1 == undefined
      ) {
        delete rest.mailAddress;
      } else {
        rest.mailAddress.city = rest.mailAddress.city.toString();
      }
      if (
        rest.address.addressLine1 == "" ||
        rest.address.addressLine1 == null ||
        rest.address.addressLine1 == undefined
      ) {
        delete rest.address;
      } else {
        rest.address.city = rest.address.city.toString();
      }

      if (
        rest.polizeStatus != this.oldStatus &&
        this.setStatusObject.note != null &&
        this.setStatusObject.note != ""
      ) {
        rest = { ...rest, noteForStatusChange: this.setStatusObject.note };
      }

      this.loading = true;
      // Remove empty values

      rest = this.cleanNull(rest);

      getAPI
        .put(`/patient/updatePatient/${this.$route.params.uuid}`, {
          ...rest,
        })
        .then(() => {
          notifySuccess(this.$t("medicForm.notificationPatientUpdated"));
          this.$refs.patientForm.reset();
          this.loading = false;
          this.$router.push({
            name: "Patient ddetails",
            params: { uuid: this.$route.params.uuid },
          });
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    async getPatientData() {
      let patient;
      if (this.patientDetail == null) {
        patient = (
          await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
        ).data;
      } else {
        patient = this.patientDetail;
      }
      this.form.isMaried = patient?.isMaried;
      //1ra line
      this.form.firstName = patient?.firstName;
      this.form.midleName = patient?.midleName;
      this.form.lastName = patient?.lastName;

      //2da line
      this.last4ssn = patient?.ssn;
      this.form.birthDate = patient?.birthDate;
      this.form.isMale = patient?.isMale;
      this.form.homePhone = patient?.homePhone
        ? patient?.homePhone.replace("+1", "")
        : "";
      this.homePhone = this.form.homePhone;
      this.form.email = patient?.email ? patient?.email : "";

      //3ra line
      this.form.legalStatus = patient?.legalStatus;
      this.form.idNumber = patient?.idNumber;
      this.form.expDate = patient?.expDate
        ? patient?.expDate.split("T")[0]
        : "";

      //4ta line
      const stateId = patient.address ? Number(patient?.address.state) : 23;
      this.state = stateId;
      this.loadingCities = true;
      const cities = await this.actGetCityByState(stateId);
      this.stateCities = cities;
      this.form.address.addressLine1 = patient.address
        ? patient?.address.addressLine1
        : "";
      this.form.address.addressLine2 = patient?.address
        ? patient.address.addressLine2
        : "";
      this.form.address.city = patient?.address
        ? Number(patient.address.city)
        : 0;
      this.form.address.zipCode = patient?.address
        ? patient.address.zipCode
        : "";
      this.loadingCities = false;

      //5ta line
      const stateId1 =
        patient.mailAddress && Number(patient?.mailAddress.state) != 0
          ? Number(patient?.mailAddress.state)
          : 23;
      this.state1 = stateId1;
      this.loadingCities1 = true;
      const cities1 = await this.actGetCityByState(stateId1);
      this.stateCities1 = cities1;
      this.form.mailAddress.addressLine1 = patient.mailAddress
        ? patient?.mailAddress.addressLine1
        : "";
      this.form.mailAddress.addressLine2 = patient?.mailAddress
        ? patient.mailAddress.addressLine2
        : "";
      this.form.mailAddress.city = patient?.mailAddress
        ? Number(patient.mailAddress.city)
        : 0;
      this.form.mailAddress.zipCode = patient?.mailAddress
        ? patient.mailAddress.zipCode
        : "";
      this.loadingCities1 = false;

      //6ta line
      this.form.employedCompany = patient?.employedCompany;
      this.form.workPhone = patient?.workPhone
        ? patient?.workPhone.replace("+1", "")
        : "";
      this.form.ocupation = patient?.ocupation;
      this.form.anualIncome = patient?.anualIncome;

      //7ma line
      this.form.contactPreference = patient?.contactPreference;
      this.form.preferLanguage = patient?.preferLanguage;
      this.form.sourceId = patient?.sourceNom ? patient.sourceNom.id : 0;
      this.form.tagId = patient?.tagNom ? patient.tagNom.id : null;

      //8va line
      this.companySelect = patient.company ? patient.company.id : null;
      this.form.companyStateId = patient.companyState
        ? patient.companyState.id
        : null;
      this.form.policyPlan = patient?.policyPlan;
      this.form.binderDay = patient?.binderDay;

      //9na line
      this.form.polizeNumber = patient.polizeNumber ? patient.polizeNumber : "";
      this.form.effectiveDate = patient.effectiveDate
        ? patient.effectiveDate.split("T")[0]
        : "";
      this.form.monthlyPay = patient.monthlyPay ? patient.monthlyPay : 0;
      this.oldStatus = patient.polizeStatus ? patient.polizeStatus : null;
      this.form.polizeStatus = patient.polizeStatus
        ? patient.polizeStatus
        : null;
      this.form.members = patient.members ? patient.members : null;
    },
    deletePhone() {
      this.loading = true;
      getAPI
        .put("/patient/setNull/" + this.$route.params.uuid, {
          emergencyPhone: false,
        })
        .then((res) => {
          this.loading = false;
          this.form.emergencyPhone = "";
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    reset() {
      this.$refs.patientForm.reset();
    },
    cancel() {
      this.$refs.patientForm.reset();
      this.loading = false;
      this.$router.push({
        name: "Patient ddetails",
        params: { uuid: this.$route.params.uuid },
      });
    },

    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },

    getExpiration(month, year) {
      const date = moment(year);
      date.startOf("year").add(month - 1, "month");

      return date.toISOString();
    },
    getYears() {
      const yearsList = [];
      const currentYear = new Date().getFullYear();

      for (let i = 1; i <= 10; i++) {
        yearsList.push(currentYear + i);
      }

      return yearsList;
    },

    getCompanyState(id) {
      this.loadingCompanyStates = true;
      getAPI
        .post("/company-state-price/filterList", { companyId: id })
        .then((res) => {
          this.loadingCompanyStates = false;
          this.companyStates = res.data;
        })
        .catch((error) => {
          this.loadingCompanyStates = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.cerofnat {
  padding-top: 2px !important;
}
</style>
<i18n>
{
  "en": {
    "title": "Add a credit card",
    "cardNumber": "Credit card number",
    "cardHolder": "Card Holder",
    "expirationDate": "Expiration date",
    "cvc": "Card verification code (CVC)",
    "addCard": "Add card",
    "confirm": "Confirm",
    "year": "Year",
    "month": "Month",
    "formatError": "Wrong format, type a number",
    "yearWrong": "The year provided have to major to current year",
    "lengthYearError": "The year must have four characters",
    "wrongCard": "You must use one of the following cards: Visa, MasterCard, American Express, Diners Club, Discover or JCB",
    "formatCvcError": "Invalid CVC, only 3 or 4 digits are accepted",
    "cardSelected": "The card has been added, select it from the list",
    "January": "January",
    "February": "February",
    "March": "March",
    "April": "April",
    "May": "May",
    "June": "June",
    "July": "July",
    "August": "August",
    "September": "September",
    "October": "October",
    "November": "November",
    "December": "December",
    "addOne": "Card Data",
    "epayaddOne": "USAePay Card Data"
  },
  "es": {
    "title": "Agregar tarjeta de crédito",
    "cardNumber": "Número de tarjeta de crédito",
    "expirationDate": "Fecha de expiración",
    "cvc": "CVC inválido, solo se aceptan 3 o 4 dígitos",
    "addCard": "Agregar tarjeta",
    "confirm": "Confirmar",
    "year": "Año",
    "month": "Mes",
    "formatError": "Formato erróneo, escriba un número",
    "yearWrong": "El año proporcionado debe ser mayor al año actual.",
    "lengthYearError": "El año debe tener cuatro caracteres",
    "wrongCard": "Debe usar una de las siguientes tarjetas: Visa, MasterCard, American Express, Diners Club, Discover o JCB",
    "formatCvcError": "CVC inválido",
    "cardSelected": "La tarjeta ha sido añadida, selecciónala de la lista",
    "January": "Enero",
    "February": "Febrero",
    "March": "Marzo",
    "April": "Abril",
    "May": "Mayo",
    "June": "Junio",
    "July": "Julio",
    "August": "Agosto",
    "September": "Septiebre",
    "October": "Octubre",
    "November": "Noviembre",
    "December": "Diciembre",
    "addOne": "Datos de la tarjeta",
     "epayaddOne": "Datos de la tarjeta USAePay"
  }
}
</i18n>
